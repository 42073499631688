import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faTriangleExclamation, faCircleCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { Flex } from '../index';
import { BaseToast } from '../../Styles/Toast';

import './custom-toast.css';
import { Props } from '../../Interfaces/Toast';

const MessageIcon = ({ type, message }: Omit<Props, 'status'>) => {
  switch (type) {
    case 'success':
    case 'normal':
      return (
        <Flex size={'small'} align={'center'}>
          <FontAwesomeIcon icon={faCircleCheck} />
          <span>{message}</span>
        </Flex>
      );
    case 'info':
      return (
        <Flex size={'small'} align={'center'}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span>{message}</span>
        </Flex>
      );
    case 'error':
      return (
        <Flex size={'small'} align={'center'}>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          <span>{message}</span>
        </Flex>
      );
  }
};

export const notify = ({ status, message, type }: Props) => {
  toast(
    ({ closeToast }) => {
      return (
        <BaseToast status={status} type={type}>
          <Flex align={'center'} justify={'space-between'} size={'medium'}>
            <MessageIcon type={type} message={message} />
            <FontAwesomeIcon icon={faX} onClick={closeToast}></FontAwesomeIcon>
          </Flex>
        </BaseToast>
      );
    },
    { autoClose: type !== 'error' ? 4000 : false },
  );
};
