import { ENV, PRO } from 'constants/EnvTypes';
import dayjs from 'dayjs';

const UPDATE_STAT_TIME = 'stat/UPDATE_STAT_TIME' as const;
const UPDATE_ACTIVE_BOTID = 'stat/UPDATE_ACTIVE_BOTID' as const;
const UPDATE_ACTIVE_BLOCKID = 'stat/UPDATE_ACTIVE_BLOCKID' as const;
const UPDATE_ACTIVE_SCENARIO = 'stat/UPDATE_ACTIVE_SCENARIO' as const;
const UPDATE_ACTIVE_SCENARIOID = 'stat/UPDATE_ACTIVE_SCENARIOID' as const;
const UPDATE_SCENARIO_LIST = 'stat/UPDATE_SCENARIO_LIST' as const;
const UPDATE_STAT_TITLE = 'stat/UPDATE_STAT_TITLE' as const;
const UPDATE_REPORT_HOOK = 'stat/UPDATE_REPORT_HOOK' as const;
const UPDATE_DEPLOY_SCENARIO_LIST = 'stat/UPDATE_DEPLOY_SCENARIO_LIST' as const;
const UPDATE_GLOBAL_LOADING = 'stat/UPDATE_GLOBAL_LOADING' as const; // 페이지 접근 시 기본 로딩
const UPDATE_ACCESS_CONTROL = 'stat/UPDATE_ACCESS_CONTROL' as const; // 통계 데이터 미 생성 확인

export const updateStatTime = (statTime: {
  startDate: Date;
  endDate: Date;
  periodValue: number;
  periodType: string;
}) => ({
  type: UPDATE_STAT_TIME,
  payload: statTime,
});

export const updateStatTitle = (data: any) => ({
  type: UPDATE_STAT_TITLE,
  payload: data,
});

export const updateActiveBlockId = (blockId: string) => ({
  type: UPDATE_ACTIVE_BLOCKID,
  payload: blockId,
});

export const updateActiveBotId = (botId: string) => ({
  type: UPDATE_ACTIVE_BOTID,
  payload: botId,
});

export const updateActiveScenario = (scenario: any) => ({
  type: UPDATE_ACTIVE_SCENARIO,
  payload: scenario,
});

export const updateActiveScenarioId = (scenarioId: string) => ({
  type: UPDATE_ACTIVE_SCENARIOID,
  payload: scenarioId,
});

export const updateScenarioList = (scenarioList: any[]) => ({
  type: UPDATE_SCENARIO_LIST,
  payload: scenarioList,
});

export const updateReportHook = (hook: boolean) => ({
  type: UPDATE_REPORT_HOOK,
  payload: hook,
});

export const updateDeployScenarioList = (scenarioList: any[]) => ({
  type: UPDATE_DEPLOY_SCENARIO_LIST,
  payload: scenarioList,
});
export const updateGlobalLoading = (globalLoading: boolean) => ({
  type: UPDATE_GLOBAL_LOADING,
  payload: globalLoading,
});
export const updateAccessControl = (data: { globalLoading: boolean; isShutDown: boolean }) => ({
  type: UPDATE_ACCESS_CONTROL,
  payload: data,
});
interface StatDataProps {
  statTitle: string;
  statSubTitle: string;
  startDate: Date;
  endDate: Date;
  periodValue: number;
  periodType: string;
  hook: boolean;
  globalLoading: boolean;
  accessControl: boolean;
  isShutDown: boolean;

  activeBotId?: string;
  activeScenarioId?: string;
  activeScenario?: any;
  scenarioList?: any[];
  activeBlocks?: any[];
  activeBlockId?: string;
  deployScenarioList?: any[];
}

export const before5 = dayjs().hour() < 5;
export const statStartDate = ENV === PRO ? dayjs('2024-12-16') : dayjs('2024-09-12');
const initStartDate = dayjs()
  .subtract(before5 ? 8 : 7, 'day')
  .startOf('day');

const initialState: StatDataProps = {
  statTitle: '대시보드',
  statSubTitle: 'main',
  startDate: initStartDate.isAfter(statStartDate) ? initStartDate.toDate() : statStartDate.toDate(),
  endDate: dayjs()
    .subtract(before5 ? 2 : 1, 'day')
    .endOf('day')
    .toDate(),
  periodValue: 7,
  periodType: 'day',
  hook: true,
  globalLoading: true,
  accessControl: false,
  isShutDown: false,
};

type StatDataAction =
  | ReturnType<typeof updateActiveBlockId>
  | ReturnType<typeof updateActiveScenario>
  | ReturnType<typeof updateActiveScenarioId>
  | ReturnType<typeof updateActiveBotId>
  | ReturnType<typeof updateStatTime>
  | ReturnType<typeof updateScenarioList>
  | ReturnType<typeof updateStatTitle>
  | ReturnType<typeof updateReportHook>
  | ReturnType<typeof updateDeployScenarioList>
  | ReturnType<typeof updateGlobalLoading>
  | ReturnType<typeof updateAccessControl>;

function StatData(state: StatDataProps = initialState, action: StatDataAction) {
  switch (action.type) {
    case UPDATE_STAT_TIME: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ACTIVE_BOTID: {
      return {
        ...state,
        activeBotId: action.payload,
      };
    }
    case UPDATE_ACTIVE_BLOCKID: {
      return {
        ...state,
        activeBlockId: action.payload,
      };
    }
    case UPDATE_ACTIVE_SCENARIOID: {
      return {
        ...state,
        activeScenarioId: action.payload,
      };
    }
    case UPDATE_ACTIVE_SCENARIO: {
      return {
        ...state,
        activeScenario: action.payload,
      };
    }
    case UPDATE_SCENARIO_LIST: {
      return {
        ...state,
        scenarioList: action.payload,
      };
    }
    case UPDATE_STAT_TITLE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPDATE_REPORT_HOOK: {
      return {
        ...state,
        hook: action.payload,
      };
    }
    case UPDATE_DEPLOY_SCENARIO_LIST: {
      return {
        ...state,
        deployScenarioList: action.payload,
      };
    }

    case UPDATE_GLOBAL_LOADING: {
      return {
        ...state,
        globalLoading: action.payload,
      };
    }

    case UPDATE_ACCESS_CONTROL: {
      return {
        ...state,
        accessControl: action.payload.globalLoading,
        isShutDown: action.payload.isShutDown,
      };
    }
    default: {
      return state;
    }
  }
}

export default StatData;
